<template>
  <a-layout id="layout_container">
    <a-layout-sider
      v-model:collapsed="collapsed"
      :trigger="null"
      collapsible
      width="255"
    >
      <LayoutAside
        :collapsed="collapsed"
        :selectedKeys="selectedKeys"
        :openKeys="openKeys"
        @handleSetActiveKey="handleSetActiveKey"
        @handleSetSelectedKeys="handleSetSelectedKeys"
        @handleSetOpenKeys="handleSetOpenKeys"
        @handleSetTabs="handleSetTabs"
      />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <LayoutHeader @collapsed="handleCollapsed" :userInfo="userInfo" />
      </a-layout-header>
      <a-layout-content>
        <LayoutContent
          :tabs="tabs"
          :activeKey="activeKey"
          @handleSetActiveKey="handleSetActiveKey"
          @handleSetOpenKeys="handleSetOpenKeys"
          @handleSetSelectedKeys="handleSetSelectedKeys"
          @handleSetTabs="handleSetTabs"
        />
      </a-layout-content>
      <a-layout-footer>
        <LayoutFooter />
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import LayoutAside from "./components/Aside.vue";
import LayoutHeader from "./components/Header.vue";
import LayoutContent from "./components/Content.vue";
import LayoutFooter from "./components/Footer.vue";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
export default defineComponent({
  components: {
    LayoutAside,
    LayoutHeader,
    LayoutContent,
    LayoutFooter,
  },
  setup() {
    const state = reactive({
      userInfo: JSON.parse(sessionStorage.getItem("userinfo")),
      collapsed: sessionStorage.getItem("collapsed")
        ? eval(sessionStorage.getItem("collapsed"))
        : false,
      tabs: sessionStorage.getItem("tabs")
        ? JSON.parse(sessionStorage.getItem("tabs"))
        : [
            // 默认tabs里面有‘首页’，且没有closable属性，不能删除
            {
              title: "首页",
              //index: 'dashboard',
              key: "dashboard",
              closable: false,
              parent: "Console",
            },
          ],
      activeKey: sessionStorage.getItem("activeKey")
        ? JSON.parse(sessionStorage.getItem("activeKey"))
        : { key: "dashboard" },
      openKeys: sessionStorage.getItem("openKeys")
        ? [sessionStorage.getItem("openKeys")]
        : ["Console"],
      selectedKeys: sessionStorage.getItem("selectedKeys")
        ? [sessionStorage.getItem("selectedKeys")]
        : ["/dashboard"],
    });
    onMounted(() => {
      sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
      sessionStorage.setItem("activeKey", JSON.stringify(state.activeKey));
      window.addEventListener("setItem", () => {
        state.userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
      });
      state.userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
    });
    const handleCollapsed = (value) => {
      console.log(value);
      const bool = !state.collapsed;
      state.collapsed = bool;
      sessionStorage.setItem("collapsed", bool);
    };

    const handleSetTabs = (tabs) => {
      state.tabs = tabs;
    };

    const handleSetActiveKey = (key) => {
      state.activeKey = key;
    };

    const handleSetOpenKeys = (openKeys) => {
      state.openKeys = openKeys;
    };

    const handleSetSelectedKeys = (selectedKeys) => {
      state.selectedKeys = selectedKeys;
    };

    return {
      ...toRefs(state),
      handleCollapsed,
      handleSetActiveKey,
      handleSetOpenKeys,
      handleSetSelectedKeys,
      handleSetTabs,
    };
  },
});
</script>
<style lang="scss">
#layout_container {
  min-height: 100vh;
}
.ant-layout-content {
  margin: 2px !important;
  padding: 2px !important;
}
// .code-box-demo {
//   text-align: center;
// }
.ant-layout-header,
.ant-layout-footer {
  color: rgb(58, 55, 55);
  background: rgb(250, 250, 250);
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  color: #fff;
  //line-height: 120px;
  background: #c9e1f8;
}
.ant-layout-content {
  min-height: 120px;
  color: #fff;
  line-height: 120px;
  background: rgb(250, 250, 250);
}
</style>